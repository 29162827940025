import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AuthComponent } from './auth.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AuthMaterialModule } from './auth-material/auth-material.module';
import { environment } from '../../../environments/environment';

import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { connectFunctionsEmulator, FunctionsModule, getFunctions, provideFunctions } from '@angular/fire/functions';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { provideAuth, connectAuthEmulator, getAuth } from '@angular/fire/auth';
import { AuthService } from './auth.service';
import { HttpClientModule } from '@angular/common/http';

let resolvePersistenceEnabled: (enabled: boolean) => void;

export const persistenceEnabled = new Promise<boolean>(resolve => {
  resolvePersistenceEnabled = resolve;
});


@NgModule({
  declarations: [
    AuthComponent,
  ],
  imports: [
    BrowserModule,
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
      }
      return auth;
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfigLanding)),
    provideFunctions(() => {
      const functions = getFunctions();
      // if (environment.useEmulators) {
      // connectFunctionsEmulator(functions, 'localhost', 5001);
      // }
      return functions;
    }),
    AuthMaterialModule,
    HttpClientModule
  ],
  providers: [ AuthService ],
  bootstrap: [ AuthComponent ]
})
export class AuthModule { }
