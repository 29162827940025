import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  authenticateUser(idToken: any) {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${idToken}`, withCredentials: 'true'
    })
    // const url = 'https://localhost:5001/coaxdesigns-ef632/us-central1/authenticateUserRequest'
    // const url = 'https://authenticateuserrequest-j3coqrzsra-uc.a.run.app'
    const url = 'https://coaxdes.com/authenticateUserRequest'
    // make http request to /authenticateUserRequest with idToken
    console.log(`Bearer ${idToken}`)
    this.http.get(url, { headers })
      .subscribe({
        // next: (result: any) => {
        //   // console.log(`Authenticated: ${JSON.stringify(result)}`);
        //   // this.test$ = of(JSON.stringify(result))
        // },
        error: (error: any) => {
          console.log('Function error: ', error);
        },
        complete: () => {
          console.log('Function complete');
        }
      });
  }
}
