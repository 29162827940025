export const environment = {
  production: false,
  firebaseConfigLanding: {
    apiKey: "AIzaSyADdjZrpEGXG_Dv9Q86_uE6ODGZcXBt1Vo",
    authDomain: "coaxdesigns-ef632.firebaseapp.com",
    databaseURL: "https://coaxdesigns-ef632-default-rtdb.firebaseio.com",
    projectId: "coaxdesigns-ef632",
    storageBucket: "coaxdesigns-ef632.appspot.com",
    messagingSenderId: "269522117078",
    appId: "1:269522117078:web:5ec053e292ef6855e88477",
    measurementId: "G-XH264LC343"
  },
  firebaseConfigAuth: {
    apiKey: "AIzaSyADdjZrpEGXG_Dv9Q86_uE6ODGZcXBt1Vo",
    authDomain: "coaxdesigns-ef632.firebaseapp.com",
    databaseURL: "coaxdesigns-ef632-default-rtdb.firebaseio.com",
    projectId: "coaxdesigns-ef632",
    storageBucket: "coaxdesigns-ef632.appspot.com",
    messagingSenderId: "269522117078",
    appId: "1:269522117078:web:59720b2ebab99605e88477",
    measurementId: "G-B85SWF5GPP",
    emulator: {
      host: 'localhost',
      port: 8080
    }
  },
  firebaseCongigAdmin: {
    apiKey: "AIzaSyADdjZrpEGXG_Dv9Q86_uE6ODGZcXBt1Vo",
    authDomain: "coaxdesigns-ef632.firebaseapp.com",
    databaseURL: "https://coaxdesigns-ef632-default-rtdb.firebaseio.com",
    projectId: "coaxdesigns-ef632",
    storageBucket: "coaxdesigns-ef632.appspot.com",
    messagingSenderId: "269522117078",
    appId: "1:269522117078:web:f8f4bdc334a20ce1e88477",
    measurementId: "G-CHBNYKWT90"
  },
  firebaseConfigDashboard: {
    apiKey: "AIzaSyADdjZrpEGXG_Dv9Q86_uE6ODGZcXBt1Vo",
    authDomain: "coaxdesigns-ef632.firebaseapp.com",
    databaseURL: "https://coaxdesigns-ef632-default-rtdb.firebaseio.com",
    projectId: "coaxdesigns-ef632",
    storageBucket: "coaxdesigns-ef632.appspot.com",
    messagingSenderId: "269522117078",
    appId: "1:269522117078:web:69971bcf6adb949ae88477",
    measurementId: "G-ZGBDP3KD3N"
  },
  baseURL: 'localhost:4200',
  stripePublishableKey: 'YOUR_STRIPE_PUBLISHABLE_KEY',
  useEmulators: true, // Set this to true in your development environment
};
