import { Component, OnInit, OnDestroy, PLATFORM_ID, isDevMode, ApplicationRef, Optional } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { EmailAuthProvider, signInWithRedirect } from '@angular/fire/auth';
import firebase from '@firebase/app-compat';
import { EMPTY, Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { trace, traceUntilFirst } from '@angular/fire/compat/performance';
import { Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Auth, GoogleAuthProvider, User, authState, signInAnonymously, signInWithPopup, signOut } from '@angular/fire/auth';
import { connectFunctionsEmulator, getFunctions, Functions, httpsCallableData, httpsCallable, HttpsCallable, HttpsCallableResult } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';

@Component({
  selector: 'auth-root',
  templateUrl: './auth.component.html',
  styleUrls: [ './auth.component.scss' ]
})
export class AuthComponent implements OnInit, OnDestroy {
  title = isDevMode() ? 'Development' : 'Auth';
  private readonly userDisposable: Subscription | undefined;
  public readonly user: Observable<User | null> = EMPTY;

  showLoginButton = false;
  showLogoutButton = false;

  test: string = 'test';

  response$: Observable<any> = EMPTY;
  helloWorldFunction: (data: any) => Observable<any>;;
  redirectFunction: (data: any) => Observable<any>;;
  authFunction: HttpsCallable

  test$: Observable<any> = of();

  constructor(appRef: ApplicationRef, @Optional() private auth: Auth, private titleService: Title, functions: Functions, private router: Router, private authService: AuthService) {
    this.titleService.setTitle(this.title);

    if (isDevMode()) {
      appRef.isStable.pipe(
        debounceTime(200),
        distinctUntilChanged(),
      ).subscribe(it => {
        console.log('isStable', it);
      });
    }

    if (auth) {
      this.user = authState(this.auth);
      this.userDisposable = authState(this.auth).pipe(
        traceUntilFirst('auth'),
        map(u => !!u)
      ).subscribe(isLoggedIn => {
        this.showLoginButton = !isLoggedIn;
        this.showLogoutButton = isLoggedIn;
      });
    }

    this.helloWorldFunction = httpsCallableData(functions, 'helloWorld', { timeout: 3_000 });
    this.redirectFunction = httpsCallableData(functions, 'redirectFunction', { timeout: 3_000 });
    this.authFunction = httpsCallable(functions, 'authFunction', { timeout: 3_000 })
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if (this.userDisposable) {
      this.userDisposable.unsubscribe();
    }
  }

  async login() {
    return await signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  async loginAnonymously() {
    this.test = 'hello world'
    // return await signInWithRedirect(this.auth, new GoogleAuthProvider());
    return await signInAnonymously(this.auth).then((userCredential) => {
      // this.response$ = this.helloWorldFunction({});
      // userCredential.user!.getIdToken().then((idToken) => {
      //   this.authFunction({ token: idToken }).then((result) => {
      //     const data: any = result.data
      //     this.response$ = of(data);
      //     this.test = data.redirectUrl.toString();
      //     // this.router.navigateByUrl(data.redirectUrl.toString());
      //     window.location.href = data.redirectUrl.toString();
      //   });
      // });

      userCredential.user
        ?.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          // Send token to your backend via HTTPS
          // ...
          this.authService.authenticateUser(idToken)
          // console.log(`Response: ${result}`)
        })
        .catch((error) => {
          // Handle error
        });
    });
  }

  async logout() {
    return await signOut(this.auth);
  }

  // authenticate(idToken: any) {
  //   const headers = new HttpHeaders({ 'Authorization': `Bearer ${idToken}` })
  //   const url = 'http://localhost:5001/coaxdesigns-ef632/us-central1/authenticateUserRequest'
  //   // make http request to /authenticateUserRequest with idToken
  //   console.log(`Bearer ${idToken}`)
  //   this.http.get(url, { headers }).subscribe({
  //     next: (result: any) => {
  //       console.log(`Authenticated: ${result.toString()}`);
  //       this.test$ = of(JSON.stringify(result))
  //     },
  //     error: (error: any) => {
  //       console.log('Function error: ', error);
  //     },
  //     complete: () => {
  //       console.log('Function complete');
  //     }
  //   });
  // }
}
